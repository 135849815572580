import React, { useState, useEffect } from 'react'
import { useHistory } from "react-router";
import axios from "axios";
import { ROOT_URL } from "../../../config";
import { setExpert } from "../../../stores/actions/expert";
import { useSelector, useDispatch } from "react-redux";
import { expertDataSelector } from "../../../stores/selectors/expert";
import { HomeOutlined, UsergroupAddOutlined, FileTextOutlined, CustomerServiceOutlined, SolutionOutlined, LogoutOutlined } from '@ant-design/icons';
import UviLogo from "../../common/uviLogo";
import ProfileCompletion from "../../common/profileCompletionComp";
import './Navbar.css'
import { Layout, Menu } from 'antd';
const { Sider } = Layout;

const Navbar = ({ title, defaultIndex }) => {

    const history = useHistory();
    const dispatch = useDispatch();
    const [expertData, setExpertData] = useState({})
    const _expertData = useSelector((state) => expertDataSelector(state));

    useEffect(() => {
        const getData = async () => {
            const resp = await axios.get(ROOT_URL + "/profile", {
                withCredentials: true,
                credentials: "include",
                params: null,
            });
            if (resp && resp.data && resp.data.responseData)
                dispatch(setExpert(resp.data.responseData[0]));
        };
        getData();
    }, []);

    useEffect(() => {
        if (_expertData && _expertData.name) {
            setExpertData(_expertData)
        }
    }, [_expertData])

    let Links = [
        { name: "All Prescription", link: "/" },
        { name: "My Consults", link: "/" },
    ];

    const handleLogout = async () => {
        const response = await axios.post(ROOT_URL + "/logout",
          { mobileNumber: expertData?.calling_number },
          {
            withCredentials: true,
            credentials: "include",
            params: null,
          });
        if (response?.status == 200)
          window.location.href = '/login'
    }

    const navList = [
        {label: "Home", icon: <HomeOutlined />, href: '/'},
        {label: "All Patient", icon: <UsergroupAddOutlined />, href: '/patients'},
        {label: "Prescriptions", icon: <FileTextOutlined />, href: '/prescriptions'},
        {label: "Successful Referrals", icon: <SolutionOutlined />, href: '/successfulReferrals'},
        {label: "Support", icon: <CustomerServiceOutlined />, href: '/support'},
        {label: "Logout", icon: <LogoutOutlined />, onClick: handleLogout},
    ]

    return (
        <div>
            <Sider
                breakpoint="lg"
                className="sider"
                width={250}
                style={{ backgroundColor: "#fff", borderColor: "rgb(39, 80, 112)", height: "100vh" }}
            >
                <div style={{ marginTop: 10, marginBottom: 18 }}>
                    <div style={{ marginLeft: 20 }}>
                        <UviLogo />
                    </div>
                </div>
                <div className='sidebar-profile'>

                    <ProfileCompletion expertData={expertData} />

                </div>
                <div style={{paddingTop:"8px"}}></div>
                <Menu
                    mode="inline"
                    defaultSelectedKeys={[`${defaultIndex}`]}
                    onClick={(item) => {
                        const href = navList[item.key - 1]?.href
                        href ? history.push(href) : navList[item.key - 1]?.onClick()
                    }}
                    items={navList.map(
                        ({label, icon}, index) => ({
                            key: String(index + 1),
                            label,
                            icon
                        }),
                    )}
                />
            </Sider>
        </div>
    )
}

export default Navbar